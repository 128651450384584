<template>
  <div>
    <kbutton
      :icon="'pencil'"
      :theme-color="'primary'"
      :fill-mode="'outline'"
      :size="'small'"
      @click="editHandler"
    >
    </kbutton>
  </div>
</template>
<script>
import { Button } from "@progress/kendo-vue-buttons";

export default {
  components: {
    kbutton: Button,
  },
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String,
  },
  methods: {
    editHandler: function () {
      this.$emit("edit", this.dataItem);
    },
  },
};
</script>
