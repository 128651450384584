<template>
  <section>
    <confirmation ref="Confirmation"></confirmation>
    <div class="base-header-text">
      <p>
        This is where you can add, review, and edit all physical addresses associated with your
        company. (If you are associated with more than one company, the page reflects only the one
        that you’ve currently selected.)
      </p>
    </div>
    <div class="additional-site-grid-header mb-3">
      <h1 class="bold-header-text app-header">Main Company Location</h1>
      <div>
        <i
          class="simple-icon-info"
          v-b-tooltip.hover.left
          title="Your organization's headquarters or primary location. Once created, your view will change to the new headquarters or primary location's dashboard."
        ></i>
        &nbsp;
        <button class="base-button" @click="addNewMainLocation">Add New</button>
      </div>
    </div>
    <div style="margin-top: 1.75rem">
      <b-card class="dashboard-card">
        <kendo-grid
          :sortable="false"
          :sort="sort"
          :data-items="gridDataMainLocation"
          :columns="tableColumnsMainLocation"
          @datastatechange="dataStateChange"
        >
          <template v-slot:myTemplate="{ props }">
            <td class="k-command-cell">
              <customEditOnly :data-item="props.dataItem" @edit="editRecord" />
            </td>
          </template>
          <template v-slot:addressTemplate="{ props }">
            <td class="k-command-cell">
              <address
                v-for="(address, index) in props.dataItem.companyAddress.split('||')"
                :key="`address_${index}`"
              >
                {{ address }}
              </address>
            </td>
          </template>
        </kendo-grid>
      </b-card>
    </div>
    <br /><br />
    <div class="additional-site-grid-header mb-3">
      <h1 class="bold-header-text app-header">Additional Sites</h1>
      <div>
        <i
          class="simple-icon-info"
          v-b-tooltip.hover.left
          title="Enter all secondary locations associated with current Main Company. If you are associated with more than one Main Company, take care to enter additional sites for the correct location."
        ></i>
        &nbsp;
        <button class="base-button" @click="addNewAdditionalSite">Add New</button>
      </div>
    </div>
    <div>
      <p class="base-header-text">
        All physical locations associated with the Main Office must be included on an application.
        Click the <strong>Add New</strong> button above to get started.
      </p>
      <b-card class="dashboard-card">
        <kendo-grid
          :sortable="false"
          :sort="sort"
          :data-items="gridData"
          :columns="tableColumns"
          @datastatechange="dataStateChange"
        >
          <template v-slot:myTemplate="{ props }">
            <td class="k-command-cell">
              <custom :data-item="props.dataItem" @edit="editRecord" @remove="deleteRecord" />
            </td>
          </template>
          <template v-slot:addressTemplate="{ props }">
            <td class="k-command-cell">
              <address
                v-for="(address, index) in props.dataItem.companyAddress.split('||')"
                :key="`address_${index}`"
              >
                {{ address }}
              </address>
            </td>
          </template>
        </kendo-grid>
      </b-card>
    </div>
  </section>
</template>
<script>
import { process } from "@progress/kendo-data-query";
import { Grid } from "@progress/kendo-vue-grid";
import CompanyMixin from "../../../mixins/CompanyMixin.vue";
import { mapGetters } from "vuex";
import ButtonActions from "../../../components/Common/ButtonActions.vue";
import ButtonActionEdit from "../../../components/Common/ButtonActionEdit.vue";
import Confirmation from "../../../components/Common/Confirmation.vue";

export default {
  props: [],
  components: {
    "kendo-grid": Grid,
    custom: ButtonActions,
    customEditOnly: ButtonActionEdit,
    Confirmation,
  },
  mixins: [CompanyMixin],

  data() {
    return {
      tableColumns: [
        {
          cell: "myTemplate",
          title: "Actions",
          width: "100",
        },
        {
          field: "companyID",
          title: "Co.ID",
          width: "80px",
        },
        {
          field: "companyLegalName",
          title: "Legal Name",
          width: "150",
        },
        {
          field: "dbaCompanyName",
          title: "DBA Name",
          width: "150",
        },
        {
          title: "Address",
          cell: "addressTemplate",
          width: "250",
        },
        {
          field: "programsOfInterest",
          title: "Programs",
          width: "230",
        },
      ],
      tableColumnsMainLocation: [
        {
          cell: "myTemplate",
          title: "Actions",
          width: "80",
        },
        {
          field: "companyID",
          title: "Co.ID",
          width: "80px",
        },
        {
          field: "companyLegalName",
          title: "Legal Name",
          width: "150",
        },
        {
          field: "dbaCompanyName",
          title: "DBA Name",
          width: "150",
        },
        {
          title: "Address",
          cell: "addressTemplate",
          width: "250",
        },
        {
          field: "programsOfInterest",
          title: "Programs",
          width: "230",
        },
      ],
      gridData: [],
      gridDataMainLocation: [],
      sort: [],
      company: {},
    };
  },
  async created() {
    this.company = JSON.parse(localStorage.getItem("currentCompany"));
    this.getTableData();
  },
  methods: {
    async getTableData() {
      await this.getDashboardAdditionalSites(this.company.companyID, true);
      this.getData(this.additionalSites);
    },
    getData(data) {
      this.gridDataMainLocation = data.filter((x) => x.companyID === this.company.companyID);

      this.gridData = process(
        data.filter((x) => x.companyID !== this.company.companyID),
        {
          sort: this.sort,
        }
      );
    },
    async createAppState(dataState) {
      await this.getDashboardAdditionalSites(this.company.companyID, false);

      this.sort = dataState.sort;
      this.getData(this.additionalSites);
    },
    async dataStateChange(event) {
      await this.createAppState(event.data);
    },
    addNewAdditionalSite() {
      this.$router.push({ name: "AddAdditionalSite" });
    },
    addNewMainLocation() {
      this.$router.push({ name: "AddNewCompany" });
    },
    editRecord(dataItem) {
      this.$router.push(`${this.$appRoot}/company/${dataItem.companyID}/details/info`);
    },
    async deleteRecord(dataItem) {
      this.$refs.Confirmation.show({
        title: "Deactivate Site",
        message: `Are you sure you want to deactivate ${dataItem.companyID}?`,
        okButton: "Yes",
        cancelButton: "No",
        exitFunction: () => {},
      }).then(async (result) => {
        if (result) {
          this.setInfoMessage("");
          const itemToDeactivate = [
            {
              id: dataItem.companyID,
              active: false,
            },
          ];
          await this.activateCompany(itemToDeactivate);
          if (this.statusCode === 200) {
            this.getTableData();
          }
        }
      });
    },
  },
  computed: {
    ...mapGetters({
      getAdditionalSites: "getAdditionalSites",
    }),
    additionalSites() {
      return this.getAdditionalSites;
    },
  },
};
</script>
