var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('confirmation',{ref:"Confirmation"}),_vm._m(0),_c('div',{staticClass:"additional-site-grid-header mb-3"},[_c('h1',{staticClass:"bold-header-text app-header"},[_vm._v("Main Company Location")]),_c('div',[_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",modifiers:{"hover":true,"left":true}}],staticClass:"simple-icon-info",attrs:{"title":"Your organization's headquarters or primary location. Once created, your view will change to the new headquarters or primary location's dashboard."}}),_vm._v("   "),_c('button',{staticClass:"base-button",on:{"click":_vm.addNewMainLocation}},[_vm._v("Add New")])])]),_c('div',{staticStyle:{"margin-top":"1.75rem"}},[_c('b-card',{staticClass:"dashboard-card"},[_c('kendo-grid',{attrs:{"sortable":false,"sort":_vm.sort,"data-items":_vm.gridDataMainLocation,"columns":_vm.tableColumnsMainLocation},on:{"datastatechange":_vm.dataStateChange},scopedSlots:_vm._u([{key:"myTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{staticClass:"k-command-cell"},[_c('customEditOnly',{attrs:{"data-item":props.dataItem},on:{"edit":_vm.editRecord}})],1)]}},{key:"addressTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{staticClass:"k-command-cell"},_vm._l((props.dataItem.companyAddress.split('||')),function(address,index){return _c('address',{key:("address_" + index)},[_vm._v(" "+_vm._s(address)+" ")])}),0)]}}])})],1)],1),_c('br'),_c('br'),_c('div',{staticClass:"additional-site-grid-header mb-3"},[_c('h1',{staticClass:"bold-header-text app-header"},[_vm._v("Additional Sites")]),_c('div',[_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",modifiers:{"hover":true,"left":true}}],staticClass:"simple-icon-info",attrs:{"title":"Enter all secondary locations associated with current Main Company. If you are associated with more than one Main Company, take care to enter additional sites for the correct location."}}),_vm._v("   "),_c('button',{staticClass:"base-button",on:{"click":_vm.addNewAdditionalSite}},[_vm._v("Add New")])])]),_c('div',[_vm._m(1),_c('b-card',{staticClass:"dashboard-card"},[_c('kendo-grid',{attrs:{"sortable":false,"sort":_vm.sort,"data-items":_vm.gridData,"columns":_vm.tableColumns},on:{"datastatechange":_vm.dataStateChange},scopedSlots:_vm._u([{key:"myTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{staticClass:"k-command-cell"},[_c('custom',{attrs:{"data-item":props.dataItem},on:{"edit":_vm.editRecord,"remove":_vm.deleteRecord}})],1)]}},{key:"addressTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{staticClass:"k-command-cell"},_vm._l((props.dataItem.companyAddress.split('||')),function(address,index){return _c('address',{key:("address_" + index)},[_vm._v(" "+_vm._s(address)+" ")])}),0)]}}])})],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-header-text"},[_c('p',[_vm._v(" This is where you can add, review, and edit all physical addresses associated with your company. (If you are associated with more than one company, the page reflects only the one that you’ve currently selected.) ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"base-header-text"},[_vm._v(" All physical locations associated with the Main Office must be included on an application. Click the "),_c('strong',[_vm._v("Add New")]),_vm._v(" button above to get started. ")])}]

export { render, staticRenderFns }